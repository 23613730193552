
import React from "react"
import { injectIntl } from "gatsby-plugin-intl"
import { graphql, useStaticQuery } from "gatsby"
import styled from "styled-components"
import Img from "gatsby-image"

const OneYearIncluded = ({intl}) => {
    const images = useStaticQuery(graphql`
    query {
      img1: file(
        sourceInstanceName: { eq: "product" }
        name: { eq: "Group-DEF" }
      ) {
        childImageSharp {
          fluid(maxWidth: 1440, quality: 100) {
            originalName
            src
            srcSet
            aspectRatio
            sizes
          }
        }
      }
    }
  `)

    return (
        <OneYearIncludedSectionWrapper>
            <div className="DescriptionWrapper">
                <h5>{intl.formatMessage({ id: "homeV3OneYearTitle" })}</h5>
                <div className="Description">{intl.formatMessage({ id: "homeV3OneYearDescription" })}</div>
            </div>
            <ImageWrapper>
                <StyledImage fluid={images.img1.childImageSharp.fluid} />
            </ImageWrapper>
        </OneYearIncludedSectionWrapper>
    )
}


const OneYearIncludedSectionWrapper = styled.div`
  margin: 16px 16px 0 16px;
  
  /* Auto layout */
  display: flex;
  flex-direction: column;
  justify-content: center;

  @media (min-width: ${props => props.theme.screen.xl}) {
    margin: 64px 64px 0 64px;
    flex-direction: row-reverse;
    align-items: center;
  }
  gap: 24px;
  
  /* Primary (Pink)/60 */
  background: ${({ theme }) => theme.color.pinks.pink60};

  /* bakcround blur */
  backdrop-filter: blur(4px);

  /* Note: backdrop-filter has minimal browser support */
  border-radius: 20px;

  /* Inside auto layout */
  flex: none;
  order: 2;
  flex-grow: 0;
  
  .DescriptionWrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 24px 16px 16px;
    gap: 8px;
    
    h5 {
      margin: 0;
      ${({ theme }) => theme.font_size.large};
      font-family: ${({ theme }) => theme.font.bold};
      @media (min-width: ${props => props.theme.screen.xl}) {
        ${({ theme }) => theme.font_size.xxlarge};
      }
    }
    
    .Description {
      ${({ theme }) => theme.font_size.small};
      font-family: ${({ theme }) => theme.font.thin};
      @media (min-width: ${props => props.theme.screen.xl}) {
        ${({ theme }) => theme.font_size.regular};
      }
    }
  }
`

const ImageWrapper = styled.div`
  margin-top: 0;
  width: 100%;
  background-color: transparent;
  display: flex;
  flex-direction: row;
`

const StyledImage = styled(Img)`
  width: 100%;
  display: block;
  border-radius: 0 0 20px 20px;
`


export default injectIntl(OneYearIncluded)
